import React, { useEffect, useState } from 'react'
import Prompt from './Prompt/Prompt'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function GameTimeQue({ ar, slidechange, que, setLevel, level, q, index, onChange }) {
  const { t } = useTranslation();
  const { lang } = useParams();
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [isErr, setIsErr] = useState(false)
  const [isTimeerr, setIsTimeerr] = useState(false)
  const [isoverTime, setisoverTime] = useState(false)
  const [isoverMinute, setisoverMinutes] = useState(false)
  const [question, setQuestion] = useState(null)
  const [selected, setSelected] = useState(null)
  const [toAr, setToAr] = useState(false)
  //useffect
  useEffect(() => {
    if (lang === 'ar') {
      setToAr(true)
    }
    else {
      setToAr(false)
    }
  }, [lang])

  useEffect(() => {
    const storedAnswers = JSON.parse(localStorage.getItem("answers"))
    setSelected(null)
    if (storedAnswers) {
      if (typeof storedAnswers[index] === 'object' && !Array.isArray(storedAnswers[index]) && storedAnswers[index] !== null) {
        setHours(storedAnswers[index].hours)
        setMinutes(storedAnswers[index].minutes)
      }
      else {
        setSelected(storedAnswers[index])
      }
    }
  }, [level, slidechange])
  useEffect(() => {
    const storedAnswers = JSON.parse(localStorage.getItem("answers"))
    if (storedAnswers) {
      if (storedAnswers[index]) {
        setLevel(level + 1)
      }
    }
  }, [])
  useEffect(() => {
    const storedAnswers = JSON.parse(localStorage.getItem("answers"));
    if (storedAnswers) {
      if (storedAnswers[index]) {
        if (question) {
          if (question.type === 'time') {
            setHours(storedAnswers[index].hours)
            setMinutes(storedAnswers[index].minutes)
            if (index >= level) {
              setLevel(index)
            }
          }
          else if (question.type === 'radio') {
            setSelected(storedAnswers[index])
            if (index >= level) {
              setLevel(index)
            }
          }
        }
      }
      else {
      }
    }
  }, [level])

  useEffect(() => {
    setQuestion(q)
  }, [q])


  //function
  const handleHour = (event) => {
    setIsErr(false)
    setIsTimeerr(false)
    setisoverTime(false)
    setisoverMinutes(false)
    const newValue = event.target.value;
    if (minutes === "00" || minutes === "") {
      if (newValue > 24) {
        setisoverTime(true)
      }
      // Only update state if input is valid
      const regex = /^$|^(0?[0-9]|1[012]|2[0-4])(\.\d{1,2})?$/;
      if (regex.test(newValue)) {
        setHours(newValue);
      }
    }
    else {
      if (newValue > 23) {
        setisoverTime(true)
      }
      // Only update state if input is valid
      const regex = /^$|^(0?[0-9]|1[012]|2[0-3])(\.\d{1,2})?$/;
      if (regex.test(newValue)) {
        setHours(newValue);
      }
    }
  };
  const addHZero = (e) => {
    const oneDigit = /^[1-9]$/;
    if (oneDigit.test(e.target.value)) {
      setisoverMinutes(false)
      setisoverTime(false)
      setHours(0 + e.target.value)
    }
  }
  const addMZero = (e) => {
    const oneDigit = /^[1-9]$/;
    if (oneDigit.test(e.target.value)) {
      setisoverMinutes(false)
      setisoverTime(false)
      setMinutes(0 + e.target.value)
    }
  }
  const handleMinutes = (event) => {
    setIsErr(false)
    setisoverMinutes(false)
    setisoverTime(false)
    setIsTimeerr(false)
    const newValue = event.target.value;
    if (newValue > 59) {
      setisoverMinutes(true)
    }
    if (hours === '24') {
      setisoverTime(true)
      setMinutes('00')
    }
    else {
      // Only update state if input is valid
      const regex = /^$|^([0-5]?[0-9])(\.\d{1,2})?$/;
      if (regex.test(newValue)) {
        setMinutes(newValue);
      }
    }
  };
  function handleAnswer() {
    if (question.type === 'time') {
      if ((hours !== '' && hours !== 0 && hours !== '00') || (minutes !== '' && minutes !== 0 && minutes !== '00')) {
        const h = hours === '' || hours === '0' ? '00' : hours;
        const m = minutes === '' || minutes === '0' ? '00' : minutes;
        const data = {
          hours: h,
          minutes: m
        }
        if (hours === '00' || hours === '' || hours === 0 || hours === '0') {
          if (minutes >= 15) {
            onChange(data);
            setIsErr(false)
            setIsTimeerr(false)
            setisoverTime(false)
            setisoverMinutes(false)
          }
          else {
            setIsTimeerr(true)
          }
        }
        else {
          onChange(data);
          setIsErr(false)
          setIsTimeerr(false)
          setisoverTime(false)
          setisoverMinutes(false)
        }

      }
      else {
        setIsTimeerr(true)
      }
    }
    else if (question.type === 'radio') {
      if (selected) {
        onChange(selected);
        setSelected(null)
      }
      else {
        setIsErr(true)
      }
    }
  }
  function handleSelection(e) {
    setIsErr(false)
    setIsTimeerr(false)
    setSelected(e.target.value)
  }
  return (
    <>
      <div className="prompt_Parent">
        {
          question ?
            <Prompt que={que} message={toAr ? question.question.ar : question.question.en} level={level} />
            : ""
        }
        <div className="skew">

        </div>
      </div>
      <form>
        {
          question ?
            <div className="questionType">
              {
                question.type === 'time' ?
                  <>
                    <div className="btn_Parent">
                      <div className="Time" dir='ltr'>
                        <div className="form-group">
                          <div className="Hour">
                            <input value={hours} min={'1'} max={'2'} id='hours' name='hours' onBlur={addHZero} onChange={handleHour} type="text" placeholder='00' />
                            <label htmlFor='hours'>{t("Result.hours")}</label>
                          </div>
                          <div className="colon_dots">
                            <span></span>
                            <span></span>
                          </div>
                          <div className="minutes">
                            <input value={minutes} type="text" id='minutes' name='minutes' onBlur={addMZero} onChange={handleMinutes} placeholder='00' />
                            <label htmlFor='minutes' >{t("Result.minutes")}</label>
                          </div>
                        </div>
                        {/* <label htmlFor="">{t('Quiz.timelabel')}</label> */}
                      </div>
                    </div>
                  </>
                  :
                  ''
              }
              {
                question.type === 'radio' ?
                  <div className="QueOption">
                    <div className="form-group">
                      {
                        question.options.map((ele, index) =>
                          <div className="opt" key={index}>
                            <input type="radio" checked={parseInt(selected) === ele.id} onChange={handleSelection} value={ele.id} id={'opt' + question.id + index + ar} name={`fast`} />
                            <label htmlFor={'opt' + question.id + index + ar} className="btn_Parent">
                              <label htmlFor={'opt' + question.id + index + ar}>
                                {
                                  toAr ?
                                    ele.ar : ele.en
                                }
                              </label>
                            </label>
                          </div>
                        )
                      }
                    </div>
                  </div>
                  :
                  ''
              }
            </div>
            :
            ''
        }
        <div className="nextButton">
          <div className="QuizBtn btn_Parent btn_red" onClick={handleAnswer}>
            <button type='button'>{t("Quiz.SubmitBtnText")}</button>
          </div>
        </div>
      </form>
      {
        isErr ?
          <div className="err">
            {t("Quiz.ErrorMessage")}
          </div>
          :
          ""
      }
      {
        isTimeerr ?
          <div className="err">
            {t("Quiz.TimeErr")}
          </div>
          :
          ""
      }
      {
        isoverTime ?
          <div className="err">
            {t("Quiz.OverTimeErr")}
          </div>
          :
          ""
      }
      {
        isoverMinute ?
          <div className="err">
            {t("Quiz.OverMinutesErr")}
          </div>
          :
          ""
      }
    </>
  )
}