const checkLang = (i18n,lang) => {
    if (lang==='en') {
        i18n.changeLanguage('en');
    }
    else if(lang==='ar')
    {
        i18n.changeLanguage('ar');
    }
    else {
        i18n.changeLanguage('en');
    }
}

export {checkLang}