import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import "./Result.css"
import { useNavigate, useParams } from 'react-router-dom';
import html2canvas from "html2canvas";
import Canvas from '../../Components/Result/Canvas/Canvas';
import solution from '../../Utils/Questions/Solution.json'
import question from '../../Utils/Questions/Question.json'
import Constant from '../../Partials/Constant';
import { InlineShareButtons } from 'sharethis-reactjs';
import { BsInstagram, BsTwitter } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'
import Modal from '../../Partials/Modal';
import { calculateGameTimes } from '../../Utils/Functions/Functions';
import { Games, PlayerCards } from '../../Utils/Functions/PlayerCard';
export default function Result() {
  const { t } = useTranslation();
  const { lang } = useParams()
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const containerMBRef = useRef(null);
  const [toAR, setToAR] = useState(false)
  const [dir, setDir] = useState('ltr')
  const [answer, setAnswer] = useState(null)
  const [Profile, setProfile] = useState({})
  const [showShare, setShowShare] = useState(false);
  const [showInsta, setShowInsta] = useState(false);
  const [isCopied, setIsCopied] = useState(false)
  const [gamesNumber, setgamesNumber] = useState({})

  const [profileGames, setProfileGames] = useState({})
  const [Description, setDescription] = useState({
    en: "",
    ar: ""
  })
  const [url, setUrl] = useState('')
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 750);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (Profile.name) {
      // console.log(gamesNumber);
      let attr = [];
      for (let key in profileGames) {
        attr.push({
          name: key,
          count: gamesNumber[key]
        })
      }
      // console.log(attr[0].name);
      let u = `${lang}/share?pctitle=${Profile.img}&attrA=${attr[0].name}&attrAcount=${attr[0].count}&attrB=${attr[1].name}&attrBcount=${attr[1].count}&attrC=${attr[2].name}&attrCcount=${attr[2].count}`;
      setUrl(Constant.APP_URL + u);
    }
  }, [lang, Profile, gamesNumber])

  // const ShareContent =
  //   answer ?
  //     answer[1] ==='0' ?
  //       `Gameplay: ${answer[0].hours}hh:${answer[0].minutes ? answer[0].minutes : '00'}mm \nHunger Level: ${toAR?question[1].dependency.Yes[0].options[answer[2]].ar:question[1].dependency.Yes[0].options[answer[2]].en} \nThirst Level: ${toAR?question[1].dependency.Yes[1].options[answer[3]].ar:question[1].dependency.Yes[1].options[answer[3]].en}\nMentality Level: ${toAR?question[1].dependency.Yes[2].options[answer[4]].ar:question[1].dependency.Yes[2].options[answer[4]].en}\n`
  //       :
  //       `Gameplay: ${answer[0].hours}:${answer[0].minutes ? answer[0].minutes : '00'}\nIntensity Level: ${toAR?question[1].dependency.No[0].options[answer[2]].ar:question[1].dependency.No[0].options[answer[2]].en} \nMentality Level: ${toAR?question[1].dependency.No[1].options[answer[3]].ar:question[1].dependency.No[1].options[answer[3]].en}`
  //     : ''
  //FB Config
  const configfb = {
    alignment: 'center',
    color: 'social',
    enabledNetworks: ['facebook'],
    disabledNetworks: ['copy', 'email', 'sms'],
    networks: {
      facebook: {
        color: 'facebook',
        icon: 'fab fa-facebook',
        text: '',
        hashtag: ['#Riot'],
        quote: 'Check out this awesome post!',
        image: Constant.APP_URL + 'Profile/' + Profile ? Profile.img : '',
        shareUrl: 'https://www.facebook.com/sharer/sharer.php?u=[URL]&hashtag=[HASHTAG]&quote=[QUOTE]',
      },
    },
  };
  const handleFaceBookShare = () => {
    const u = encodeURIComponent(url);
    const hashtag = encodeURIComponent(configfb.networks.facebook.hashtag);
    const quote = encodeURIComponent(configfb.networks.facebook.quote);
    const image = encodeURIComponent(configfb.networks.facebook.image);
    const shareUrl = configfb.networks.facebook.shareUrl
      .replace('[URL]', u)
      .replace('[HASHTAG]', hashtag)
      .replace('[QUOTE]', quote)
      .replace('[IMAGE]', image);
    window.open(shareUrl, '', "width=600,height=400");
  };
  const configtw = {
    alignment: 'center',
    color: 'social',
    enabledNetworks: ['twitter'],
    disabledNetworks: ['copy', 'email', 'sms'],
    networks: {
      twitter: {
        color: 'twitter',
        icon: 'fab fa-twitter',
        text: '',
        hashtag: '#myhashtag',
        quote: 'Check out this awesome post!',
        image: Constant.APP_URL + 'Profile/' + Profile ? Profile.img : '',
        shareUrl: 'https://twitter.com/intent/tweet?url=[URL]&text=[TEXT]',
      },
    },
  };
  const handleTwitterShare = () => {
    const u = encodeURIComponent(url);
    const text = encodeURIComponent(configtw.networks.twitter.text);
    const shareUrl = configtw.networks.twitter.shareUrl
      .replace('[URL]', u)
      .replace('[TEXT]', text);
    //   .replace('[MEDIA]', image);
    //   window.open(shareUrl, '_blank');
    window.open(shareUrl, '', "width=600,height=400");
  };
  useEffect(() => {
    if (lang === 'ar') {
      setToAR(true)
      setDir('rtl')
    }
    else {
      setToAR(false)
      setDir('ltr')
    }
  }, [lang])
  useEffect(() => {
    const answers = JSON.parse(localStorage.getItem('answers'))
    if (answers) {
      setAnswer(answers)
    }
    else {
      navigate(`/${lang}`)
    }
  }, [])
  useEffect(() => {
    if (answer) {
      getSolution()
    }
  }, [answer])
  function totalMinutes(hours, minutes) {
    return hours * 60 + minutes;
  }
  function getSolution() {
    if (answer[1] == question[1].options[0].id) {
      let obj = solution.Fast.find(o => o.hunger == answer[2] && o.thirst == answer[3] && o.mentality == answer[4]);
      if (!obj) {
        navigate(`/${lang}`)
      }
      else {
        const minutes = totalMinutes(parseInt(answer[0].hours), parseInt(answer[0].minutes))
        setgamesNumber(calculateGameTimes(minutes, obj.games))
        let sProfile = shuffle(obj.profile);
        setDescription(obj.resultPara)
        setProfileGames(obj.games)
        setProfile(sProfile[0])
      }
    }
    else if (answer[1] == question[1].options[1].id) {
      let obj = solution.NoFast.find(o => o.intensity == answer[2] && o.mentality == answer[3]);
      if (!obj) {
        navigate(`/${lang}`)
      }
      else {
        const minutes = totalMinutes(parseInt(answer[0].hours), parseInt(answer[0].minutes))
        setgamesNumber(calculateGameTimes(minutes, obj.games))
        let sProfile = shuffle(obj.profile);
        setDescription(obj.resultPara)
        setProfileGames(obj.games)
        setProfile(sProfile[0]);
      }
    }
    else {
      navigate(`/${lang}`)
    }
  }

  function shuffle(array) {
    return [...array].sort(() => Math.random() - 0.5);
  }
  const handleDownload = () => {
    var container;
    if (isMobile) {
      container = containerMBRef.current;
    }
    else {
      container = containerRef.current;
    }
    html2canvas(container).then(function (canvas) {
      const link = document.createElement("a");
      link.download = Profile.img + ".png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };
  const StartQuizAgain = () => {
    localStorage.removeItem('answers')
    localStorage.removeItem('intro')
    let intro =new Array(1)
    intro[0]=true;
    localStorage.setItem('intro',JSON.stringify(intro))
    navigate(`/${lang}/quiz`)
  }
  const handleShareClose = () => {
    setShowShare(false);
  };
  const handleShareShow = () => {
    setShowShare(true);
  };
  const handleInstaClose = () => {
    setShowInsta(false);
  };
  const handleInstaShow = () => {
    setShowInsta(true);
  };
  function copyToClipboard(text) {
    navigator.clipboard.writeText(text)
      .then(() => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 3000);
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
      });
  }
  return (
    <>
      <div className="modalWrapCustom">
        <Modal show={showShare} handleClose={handleShareClose}>
          <div className="sharePopup mobileRev padLess01">
            <div className="leftInfo" dir={dir}>
              <div className="heading">
                <h2>{t('Result.sharePopup.heading')}</h2>
              </div>
              <div className="sharePara">
                <p>{t('Result.sharePopup.Description')}</p>
              </div>
              <div className="shareButtons">
                <div className="InstaBtn btn_Parent btn_transparent" onClick={() => {
                  handleShareClose()
                  handleInstaShow()
                }}>
                  <button className="btnContent">
                    <BsInstagram />
                  </button>
                </div>
                <div className="TwitterBtn btn_Parent btn_transparent" onClick={() => {
                  handleTwitterShare()
                }}>
                  <button className="btnContent">
                    <BsTwitter />
                  </button>
                </div>
                <div className="FbBtn btn_Parent btn_transparent" onClick={() => {
                  handleFaceBookShare()
                }}>
                  <button className="btnContent">
                    <FaFacebookF />
                  </button>
                </div>
              </div>
            </div>
            <div className="rightProfile buttonPopup">
              <div className="Profile">
                <img src={process.env.PUBLIC_URL + '/Profile/' + Profile.img + '.png'} alt={Profile.name} />

                <div className="overlayLine"></div>

                <div className="profileQuizTitle">
                  <h2 className="profileHead">
                    {
                      PlayerCards(Profile.img, lang)
                    }
                  </h2>
                  <span className="bgColor"></span>
                </div>

                {
                  answer ?
                    <div className="profileData" dir={dir}>
                      <div className="recommendHeading">
                        <span className="textProfile">
                          {t('Result.gameHeading')}
                        </span>
                      </div>
                      <div className="recommendedData">

                        {
                          profileGames ?

                            Object.keys(profileGames).map((key, i) =>
                              <div className="gameTime profileDetails" key={i}>
                                <span className="numProfile">{gamesNumber[key]}</span>
                                <span className="textProfile">{Games(key, lang)}</span>
                              </div>
                            )
                            : ""
                        }
                      </div>
                    </div>
                    :
                    ""
                }
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={showInsta} handleClose={handleInstaClose} >
          <div className="instaPopup instaPopupSh">
            <div className="instaHeading mbinstaHeading" dir={dir}>
              <h2>{t('Result.ShareInstaPopup.heading')}</h2>
              <p dangerouslySetInnerHTML={{ __html: t("Result.ShareInstaPopup.stepsDescription") }} />
            </div>
            <div className="InstaContent instaShareCol">
              <div className="leftInstaContent" dir={dir}>
                <div className="instaHeading" dir={dir}>
                  <h2>{t('Result.ShareInstaPopup.heading')}</h2>
                  <p dangerouslySetInnerHTML={{ __html: t("Result.ShareInstaPopup.stepsDescription") }} />
                </div>
                <div className="textContent">
                  {/* <div className="tagline">
                    <p>
                      {t("Result.ShareInstaPopup.shareContent")}
                    </p>
                  </div>
                  <div className="copyBtn" onClick={() => {
                    copyToClipboard(t("Result.ShareInstaPopup.shareContent"))
                  }}>
                    <span>
                      {
                        isCopied ?
                        t("Result.ShareInstaPopup.CopySuccessCTA")
                        :
                        t("Result.ShareInstaPopup.CopyCTA")
                      }
                    </span>
                  </div> */}
                  <div className="instaButton">
                    <div className="downloadImage btn_Parent btn_red" onClick={handleDownload}>
                      <button>
                        {t('Result.SaveImage')}
                      </button>
                    </div>
                    <div className="appBtn btn_Parent btn_transparent" onClick={() => {
                      window.open(Constant.Instagram, '_blank')
                    }}>
                      <button >
                        {t('Result.launchApp')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rightInstContent instaSharePopup">
                <div className="Profile">
                  <img src={process.env.PUBLIC_URL + '/Profile/' + Profile.img + '.png'} alt={Profile.name} />
                  {/* <img src={ProfileImage} alt="Person" /> */}
                  <div className="overlayLine"></div>

                  <div className="profileQuizTitle">
                    <h2 className="profileHead">
                      {
                        PlayerCards(Profile.img, lang)
                      }
                    </h2>
                    <span className="bgColor"></span>
                  </div>

                  {
                    answer ?
                      <div className="profileData" dir={dir}>
                        <div className="recommendHeading">
                          <span className="textProfile">
                            {t('Result.gameHeading')}
                          </span>
                        </div>
                        <div className="recommendedData">

                          {
                            profileGames ?

                              Object.keys(profileGames).map((key, i) =>
                                <div className="gameTime profileDetails" key={i}>
                                  <span className="numProfile">{gamesNumber[key]}</span>
                                  <span className="textProfile">{Games(key, lang)}</span>
                                </div>
                              )
                              : ""
                          }
                        </div>
                      </div>
                      :
                      ""
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div className="Score resultScoreWidth resultContainer">
        <div className="left_result" dir={dir}>
          <div className="left_resultInner">
            {
              answer ?
                <>
                  <h1 dangerouslySetInnerHTML={{ __html: t('Result.Heading').replace('[XHOURS]', answer[0].hours).replace('[XMINUTES]', answer[0].minutes).replace('HH', parseInt(answer[0].hours) > 1 ? t('Result.hours') : t('Result.hour')).replace('MM', parseInt(answer[0].minutes) !== 1 ? t('Result.minutes') : t('Result.minute')) }} />
                  {
                    toAR ?
                      <div className="res_Para" dangerouslySetInnerHTML={{ __html: Description.ar.replace('[XHOURS]', answer[0].hours + ':' + answer[0].minutes).replace('[TIME]', (parseInt(answer[0].hours) > 0) ? (parseInt(answer[0].hours) > 1) ? t('Result.hours') : t('Result.hour') : (parseInt(answer[0].minutes) !== 1) ? t('Result.minutes') : t('Result.minute')) }} />
                      :
                      <div className="res_Para" dangerouslySetInnerHTML={{ __html: Description.en.replace('[XHOURS]', answer[0].hours + ':' + answer[0].minutes).replace('[TIME]', (parseInt(answer[0].hours) > 0) ? (parseInt(answer[0].hours) > 1) ? t('Result.hours') : t('Result.hour') : (parseInt(answer[0].minutes) !== 1) ? t('Result.minutes') : t('Result.minute')) }} />
                  }
                </>
                :
                ""
            }
            <div className="mb_profile">
              <Canvas dir={dir} containerRef={containerMBRef} profileGames={profileGames} gamesNumber={gamesNumber} profile={Profile} />
            </div>
            <div className="res_ActBtns">
              <div className="btn_Parent btn_red" onClick={handleShareShow}>
                <button>{t('Result.ShareCTA')}</button>
              </div>
              <div className="btn_Parent btn_transparent" onClick={StartQuizAgain}>
                <button >{t('Result.QuizCTA')}</button>
              </div>
            </div>
          </div>
        </div>
        <div className="right_result">
          <Canvas dir={dir} containerRef={containerRef} profileGames={profileGames} gamesNumber={gamesNumber} profile={Profile} />
        </div>
      </div>
    </>
  )
}