import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from '../Utils/Locale/EN.json';
import ar from '../Utils/Locale/AR.json';
// "Inline" English and Arabic translations.

// We can localize to any language and any number of languages.

const resources = {

  en: {
    translation:en,
  },

  ar: {
    translation: ar,
  },

};

i18next

  .use(initReactI18next)

  .init({

    resources,

    lng: "en",

    interpolation: {

      escapeValue: false,

    },

  });

export default i18next;