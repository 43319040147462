import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { BsDiscord, BsYoutube, BsInstagram, BsTwitter } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'
import ValLogo from "../assets/Images/valorantLogo.png"
import RiotLogo from "../assets/Images/Riot_Games-Logo.wine.png"
import './Footer.css'
import Constant from './Constant';
export default function Footer() {
    const { t } = useTranslation();
    return (
        <>
            <footer>
                <div className="FooterInner">
                    <div className="ft_brand">
                        <div className="ft_volorant">
                            <Link to={Constant.Valorant} target='_blank'>
                                <img src={ValLogo} alt="" />
                            </Link>
                        </div>
                        <div className="ft_riot">
                            {/* <Link to={}  > */}
                                <img src={RiotLogo} alt="" />
                            {/* </Link> */}
                        </div>
                    </div>
                    <div className="ft_menuLink">
                        <ul className="ft_links">
                            <li>
                                <Link to={Constant.TermsCondition} target='_blank'>
                                    {t("Footer.Terms")}
                                </Link>
                            </li>
                            <li>
                                <Link to={Constant.Privacy} target='_blank'>
                                    {t("Footer.Privacy")}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="ft_socialLinks">
                        <ul className="ft_social">
                            <li>
                                <Link to={Constant.Discord} target='_blank'>
                                    <BsDiscord />
                                </Link>
                            </li>
                            <li>
                                <Link to={Constant.Youtube} target='_blank'>
                                    <BsYoutube />
                                </Link>
                            </li>
                            <li>
                                <Link to={Constant.Instagram} target='_blank'>
                                    <BsInstagram />
                                </Link>
                            </li>
                            <li>
                                <Link to={Constant.Twitter} target='_blank'>
                                    <BsTwitter />
                                </Link>
                            </li>
                            <li>
                                <Link to={Constant.Facebook} target='_blank'>
                                    <FaFacebookF />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}
