import React, { useState, useRef } from "react";
import Constant from "../../../Partials/Constant";
import ProfileImage from "../../../assets/Images/profile2.jpeg"
import { useTranslation } from 'react-i18next';
import { Games, PlayerCards } from "../../../Utils/Functions/PlayerCard";
import { useParams } from "react-router-dom";
export default function Canvas({ dir, containerRef, profileGames, gamesNumber, profile }) {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const { lang } = useParams()
  const handleImageLoad = () => {
    setIsLoaded(true);
  };
  return (
    <div className="canvas2 buttonPopup buttonPopupCanvas" ref={containerRef}>

      <div className={`Profile`}>
        <img className={isLoaded ? "loaded" : "loading"} src={process.env.PUBLIC_URL + '/Profile/' + profile.img + ".png"} onLoad={handleImageLoad} alt={profile.name} />
        <div className="overlayLine"></div>
        <div className="profileQuizTitle">
          <h2 className="profileHead">
            {
              PlayerCards(profile.img, lang)
            }
          </h2>
          <span className="bgColor"></span>
        </div>
        <div className="profileData" dir={dir}>
          <div className="recommendHeading">
            <span className="textProfile">
              {t('Result.gameHeading')}
            </span>
          </div>
          <div className="recommendedData">
            {
              profileGames ?

                Object.keys(profileGames).map((key, i) =>
                  <div className="gameTime profileDetails" key={i}>
                    <span className="numProfile">{gamesNumber[key]}</span>
                    <span className="textProfile">{Games(key, lang)}</span>
                  </div>
                )
                : ""
            }
          </div>
          {/* <div className="Intensity profileDetails">
            <span className="numProfile">100</span>
            <span className="textProfile">{t('Result.stats.Intensity')}</span>
          </div> */}
        </div>
      </div>
    </div>
  )
}
