function calculateGameTimes(totalMinutes, result) {
  // Define game timings
  const gameTimes = {
    unrated: 45,
    competitive: 45,
    spike_rush: 15,
    death_match: 10,
    escalation: 10,
    swift_play: 15,
  };

  let remainingMinutes = totalMinutes;
  const resultArray = Object.entries(result);

  // Sort the array by the values in descending order
  resultArray.sort((a, b) => b[1] - a[1]);
 

  // console.log('resultArray',resultArray);
  // Get the first, second, and third values in the sorted array
  const firstPriority = resultArray[0];
  const secondPriority = resultArray[1];
  const thirdPriority = resultArray[2];

  // get the Hours value from time for each
  let firstItemHours = totalMinutes * (firstPriority[1] / 100);
  let secondItemHours = totalMinutes * (secondPriority[1] / 100);
  let thirdItemHours = totalMinutes * (thirdPriority[1] / 100);

  // if (firstItemHours < gameTimes[firstPriority[0]]) {
  //   firstItemHours = firstItemHours + secondItemHours + thirdItemHours;
  //   secondItemHours = 0;
  //   thirdItemHours = 0;
  //   //console.log("first if");
  // } else if (secondItemHours < gameTimes[secondPriority[0]]) {
  //   secondItemHours = secondItemHours + thirdItemHours;
  //   thirdItemHours = 0;
  //   //console.log("second if");
  // }

  // console.log("firstItemHours", firstItemHours);
  // console.log("secondItemHours", secondItemHours);
  // console.log("thirdItemHours", thirdItemHours);

  // console.log("firstPriority", firstPriority[1]);
  // console.log("secondPriority", secondPriority[1]);
  // console.log("thirdPriority", thirdPriority[1]);

  const finalresult = [];

  // finalresult[firstPriority[0]] = Math.floor(firstItemHours / gameTimes[firstPriority[0]]);
  // finalresult[secondPriority[0]] = Math.floor(secondItemHours / gameTimes[secondPriority[0]]);
  // finalresult[thirdPriority[0]] = Math.floor(thirdItemHours / gameTimes[thirdPriority[0]]);

  finalresult[firstPriority[0]] = firstItemHours;
  finalresult[secondPriority[0]] = secondItemHours;
  finalresult[thirdPriority[0]] = thirdItemHours;

  // let residue = 0;
  for (const [game, hours] of Object.entries(finalresult)) {
      // console.log('residue',(hours+residue));
      finalresult[game] = Math.floor(hours / gameTimes[game]);
      // finalresult[game] = Math.floor((hours+residue) / gameTimes[game]);
  
  //   residue = hours-(finalresult[game] * gameTimes[game]);
  //   console.log('finalresult[game]',hours-(finalresult[game] * gameTimes[game]));
    remainingMinutes -= finalresult[game] * gameTimes[game]; // update remaining time
  }

  // Perform a loop to calculate games and add to the result
  for (const [game, hours] of Object.entries(finalresult)) {
    if (remainingMinutes >= gameTimes[game]) {
      // if (hours === 0) {
      //console.log(" game hours", game, hours);
      const numGames = Math.floor(remainingMinutes / gameTimes[game]);

      finalresult[game] = finalresult[game] + numGames;
      remainingMinutes = remainingMinutes - numGames * gameTimes[game];
      // }
    }
  }
  return finalresult;
}
export {calculateGameTimes}