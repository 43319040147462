import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Share.css";
import { useNavigate, useParams,useLocation  } from "react-router-dom";

import Constant from "../../Partials/Constant";

import {PlayerCards,Games} from "../../Utils/Functions/PlayerCard";

import { Helmet } from "react-helmet";

import ProfileImage from "../../assets/Images/profile2.jpeg"

export default function Share() {
  const { t } = useTranslation();
  const { lang } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = {};

  const [cardImage,setCardImage] = useState('calm_and_content');
  const [cardTitle,setCardTtitle] = useState('...');
  const [cardattr_A,setCardattr_A] = useState('...');
  const [cardattr_A_val,setCardattr_A_val] = useState('00');
  const [cardattr_B,setCardattr_B] = useState('...');
  const [cardattr_B_val,setCardattr_B_val] = useState('00');
  const [cardattr_C,setCardattr_C] = useState('...');
  const [cardattr_C_val,setCardattr_C_val] = useState('00');

  const containerRef = useRef(null);

  useEffect(() => {
    

    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setCardImage(params['pctitle']);
    setCardTtitle(PlayerCards(params['pctitle'],lang));
    setCardattr_A(Games(params['attrA'],lang));
    setCardattr_B(Games(params['attrB'],lang));
    setCardattr_C(Games(params['attrC'],lang));
    setCardattr_A_val(params['attrAcount']);
    setCardattr_B_val(params['attrBcount']);
    setCardattr_C_val(params['attrCcount']);
    // console.log('PlayerCards:',PlayerCards(params['pctitle'],lang));
    // console.log('searchParams',params);
    // console.log('cardImage',cardImage);

  }, [lang,params,cardTitle,cardImage]);
  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>test title</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet> */}
      <div className="socialShare_page">
        
          <div className="canvas2  buttonPopup buttonPopupCanvas" ref={containerRef}>
            <div className="Profile shareProfileMM" dir={lang==='ar'?'rtl':'ltr'}>
            <img src={process.env.PUBLIC_URL + '/Profile/' + cardImage+".png"} alt="Person" />
              
              <div className="overlayLine"></div>

              <div className="profileQuizTitle">
                <h2 className="profileHead">{cardTitle}</h2>
                <span className="bgColor"></span>
              </div>
              <div className="profileData">
                <div className="gameTime profileDetails">
                  <span className="numProfile">{cardattr_A_val}</span>
                  <span className="textProfile">
                    {cardattr_A}
                  </span>
                </div>
                <div className="hunger profileDetails">
                  <span className="numProfile">{cardattr_B_val}</span>
                  <span className="textProfile">
                    {cardattr_B}
                  </span>
                </div>
                <div className="thirst profileDetails">
                  <span className="numProfile">{cardattr_C_val}</span>
                  <span className="textProfile">
                    {cardattr_C}
                  </span>
                </div>
                {/* <div className="Intensity profileDetails">
                <span className="numProfile">100</span>
                <span className="textProfile">{t('Result.stats.Intensity')}</span>
              </div> */}
              </div>
            </div>
          </div>
        
      </div>
    </>
  );
}
