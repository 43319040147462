export default Object.freeze({
  APP_URL: "https://riot.ramadan.slz.io/",
  Facebook: "https://www.facebook.com/PlayVALORANT?brand_redir=107163440885389",
  Instagram: "https://www.instagram.com/valorantar/",
  Twitter: "https://twitter.com/VALORANTar",
  Youtube: "https://www.youtube.com/channel/UC4PtOhCMlWl5N8RKSRCXSMw",
  Discord: "https://discord.com/invite/xGz7M3v",
  Twitch: "https://www.twitch.tv/",
  Riot: "https://www.riotgames.com/en",
  Valorant: "https://playvalorant.com/ar-ae/",
  Privacy: "https://www.riotgames.com/en/privacy-notice",
  TermsCondition: "https://www.riotgames.com/en/terms-of-service",
});