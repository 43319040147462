import React, { useEffect, useState } from 'react'
import './Prompt.css'
import { useTranslation } from 'react-i18next';

export default function Prompt({ que, message, level }) {
  const [edited, setEdited] = useState('')
  const { t } = useTranslation();

  useEffect(() => {
    if (que !== undefined) {
      const storedAnswers = JSON.parse(localStorage.getItem("answers"))
      if (storedAnswers) {
        var str = message;
        var new_Str = str[que].replace('[XHOURS]', storedAnswers[0].hours + ":" + storedAnswers[0].minutes).replace('[TIME]',
          (parseInt(storedAnswers[0].hours) > 0) ?
            (parseInt(storedAnswers[0].hours) === 1) ?
              t('Result.oneHour') : (parseInt(storedAnswers[0].hours) === 2) ?
              t('Result.twoHour') : (parseInt(storedAnswers[0].hours) >= 3 &&parseInt(storedAnswers[0].hours) <= 10) ?
              t('Result.threeHour') : t('Result.elevHour')
              :
            (parseInt(storedAnswers[0].minutes) !== 1) ?
              t('Result.minutes') : t('Result.minute'))
        setEdited(new_Str)
      }
      else {
        setEdited(message[que])
      }
    }
  }, [level, message, que])

  return (
    <>
      <div className="Prompt promptSh" dangerouslySetInnerHTML={{ __html: edited }}>
      </div>
    </>
  )
}
