import React from 'react'
import Question from '../Components/Questions/Question'
import { useTranslation } from 'react-i18next'; 

export default function Quiz() {
    const { t } = useTranslation();
    return (
        <>
            <div className="QuizMain">
                <Question  />
            </div>
        </>
    )
}

