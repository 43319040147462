import React from 'react'
import Prompt from './Prompt/Prompt'

export default function Continue({que,buttonText,intro ,onContine}) {
    return (
        <div>
            <div className="prompt_Parent">
                <Prompt que={que}  message={intro} />
                <div className="skew">
                </div>
            </div>
            <div className="nextButton">
            <div className="QuizBtn btn_Parent btn_red" onClick={()=>{
                onContine()
            }}>
            <button  >{buttonText}</button>
            </div>
          </div>
        </div>
    )
}
