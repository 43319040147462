export function PlayerCards(title, lang) {
  const all_title_en = {
    calm_and_content: "Calm and content",
    laser_focused: "Laser-focused",
    maxed_out_mental: "Maxed out mental",
    unpredictable: "Unpredictable",
    on_the_edge: "On the edge",
    breaking_point: "Breaking point",
    bloodthirsty: "Bloodthirsty",
    unstoppable: "Unstoppable",
    relentless: "Relentless",
    unhinged: "Unhinged",
    spray_and_pray: "Spray and pray",
    erratic: "Erratic",
    immortal: "Immortal",
    flawless: "Flawless",
    ace: "Ace",
    only_human: "Only human",
    work_in_progress: "Work in progress",
    room_to_grow: "Room to grow",
    alive_and_kicking: "Alive and kicking",
    mental_master: "Mental master",
    drained_but_dangerous: "Drained but dangerous",
    wiped_out: "Wiped out",
    withering_away: "Withering away",
    drained: "Drained",
    disciplined_and_dangerous: "Disciplined and dangerous",
    next_level: "Next level",
    indestructible_spirit: "Indestructible spirit",
    unleash_the_feast: "Unleash the feast",
    furious_appetite: "Furious appetite",
    driven_by_rage: "Driven by rage",
    unbreakable: "Unbreakable",
    radiant_and_ready: "Radiant and ready",
    limitless: "Limitless",
    fasting_furious: "Fasting, furious",
    seriously_hangry: "Seriously hangry",
    running_on_empty: "Running on empty",
  };
  const all_title_ar = {
    calm_and_content: "هادئ وجاهز",
    laser_focused: "مصمّم على الهدف",
    maxed_out_mental: "عقل فعّال",
    unpredictable: "غير متوقع",
    on_the_edge: "على الحافة",
    breaking_point: "نقطة الانهيار",
    bloodthirsty: "عميل سفاح",
    unstoppable: "لا يمكن إيقافه",
    relentless: "إصرار لا ينتهي",
    unhinged: "غير متزن",
    spray_and_pray: "وداعاً لدقة التصويب",
    erratic: "رشاش عشوائي",
    immortal: "خالد",
    flawless: "مثالي",
    ace: "بارع",
    only_human: "مجرّد إنسان",
    work_in_progress: "في طور النمو",
    room_to_grow: "قابل للتطور",
    alive_and_kicking: "حيّ يرزق",
    mental_master: "صبور ومكافح",
    drained_but_dangerous: "منهك لكن خطير",
    wiped_out: "شديد التعب",
    withering_away: "حالة يرثى لها",
    drained: "منهك",
    disciplined_and_dangerous: "منضبط وخطير",
    next_level: "قوة على مستوى آخر",
    indestructible_spirit: "روح لا يمكن هزيمتها",
    unleash_the_feast: "الباحث عن الطعام",
    furious_appetite: "شهية بلا حدود",
    driven_by_rage: "لا تعترض طريقي",
    unbreakable: "صامد",
    radiant_and_ready: "راديانت ومستعد",
    limitless: "بلا حدود",
    fasting_furious: "صائم وجائع",
    seriously_hangry: "لا تعبث معي",
    running_on_empty: "جائع وضائع",
  };

  switch (lang) {
    case "ar":
      if (all_title_ar[title]) return all_title_ar[title];
      break;

    default:
      if (all_title_en[title]) return all_title_en[title];
      break;
  }

  return null;
}

export function Games(title, lang) {
  const all_games_en = {
    unrated: "Unrated",
    competitive: "Competitive",
    death_match: "DeathMatch",
    spike_rush: "Spike Rush",
    escalation: "Escalation",
    swift_play: "Swiftplay",
  };
  const all_games_ar = {
    unrated: "غير مصنّف",
    competitive: "تنافسي",
    death_match: "مباراة الموت",
    spike_rush: "سبايك راش",
    escalation: "إسكيليشن",
    swift_play: "سويفت بلاي",
  };

  switch (lang) {
    case "ar":
      if (all_games_ar[title]) return all_games_ar[title];
      break;

    default:
      if (all_games_en[title]) return all_games_en[title];
      break;
  }

  return null;
}
