import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import "./Question.css"
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import GameTimeQue from './SingleQuestions/GameTimeQue';
import questions from "../../Utils/Questions/Question.json"
import MobGif from '../../assets/GIf/HUDforanimationHorizontal.gif'
import WebGif from '../../assets/GIf/HUDforanimationVertical.gif'
import UpdatedMobGif from '../../assets/GIf/updatedAnimationMobile.gif'
import UpdatedWebGif from '../../assets/GIf/updatedAnimationWeb.gif'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Continue from './SingleQuestions/Continue';
export default function Question() {
    //States
    const [toAR, setToAR] = useState(false)
    const [direction, setdirection] = useState('ltr')
    const [answers, setAnswers] = useState(new Array(questions.length));
    const [intro, setIntro] = useState([]);
    const [level, setLevel] = useState(0)
    const [slideIndex, setSlideIndex] = useState(0);
    const { t } = useTranslation();
    const swiperRef = useRef();
    const swiperRTLRef = useRef();
    const { lang } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        // console.log('slide change');
    }, [slideIndex])

    //Useeffects
    useEffect(() => {
        if (lang === 'ar') {
            setToAR(true)
            setdirection('rtl')
        }
        else {
            setToAR(false)
            setdirection('ltr')
        }
    }, [lang])
    const handleNextSlideClick = () => {
        if ((swiperRef.current !== null && swiperRef.current.swiper !== null) || (swiperRTLRef.current !== null && swiperRTLRef.current.swiper !== null)) {
            swiperRTLRef.current.swiper.slideNext();
            swiperRef.current.swiper.slideNext();
        }
    };
    useEffect(() => {
        const storedAnswers = localStorage.getItem("answers");
        if (storedAnswers) {
            setAnswers(JSON.parse(storedAnswers));
        }
        const introSlide = localStorage.getItem("intro");
        if (introSlide) {
            setIntro(JSON.parse(introSlide))
        }

    }, []);

    useEffect(() => {
        if (swiperRef.current.swiper.isEnd || swiperRTLRef.current.swiper.isEnd) {
            navigate(`/${lang}/result`)
        }
        else {
            handleNextSlideClick()
        }
    }, [level])
    //Functions
    const handleSlideChange = (swiper) => {
        if (answers[swiper.activeIndex - 1] === null || answers[swiper.activeIndex - 1] === undefined) {
            swiper.slideTo(swiper.activeIndex - 1);
        }
        else {
            setSlideIndex(swiper.activeIndex)
        }
    };
    const handleTimeQuestionChange = (index, time, id) => {
        const newAnswers = [...answers];
        const intros = [...intro];
        for (let i = index; i < newAnswers.length; i++) {
            newAnswers[i] = null;
            intros[i] = null;
        }
        intros[index] = true;
        setIntro(intros)
        localStorage.setItem("intro", JSON.stringify(intros))
        newAnswers[index] = time
        setAnswers(newAnswers);
        localStorage.setItem("answers", JSON.stringify(newAnswers));
        setLevel(level + 1)
    };

    const handleButtonClick = (index) => {
        swiperRTLRef.current.swiper.slideTo(index);
        swiperRef.current.swiper.slideTo(index);
        setSlideIndex(index)
    };
    const handleIntro = (index) => {
        const newIntro = [...intro];
        newIntro[index] = true
        setIntro(newIntro);
        localStorage.setItem("intro", JSON.stringify(newIntro));
    }
    return (
        <>
            <div className="question questionSh">
                <div className="leftQuestion">
                    <Link to={`/${lang}`}>
                        <img src={UpdatedWebGif} alt="hudanimation" />
                    </Link>
                    <Link to={`/${lang}`}>
                        <img src={UpdatedMobGif} alt="hudanimation" />
                    </Link>
                </div>
                <div className="rightQuestion" dir={direction}>
                    <div className="QuestinInner">
                        <div className="questionSlider">
                            <div className={`SwiperParent ${toAR ? 'swiperShow' : ''}`}>
                                <Swiper
                                    dir='rtl'
                                    ref={swiperRTLRef}
                                    onSlideChange={handleSlideChange}
                                    className="mySwiper">
                                    {
                                        questions.map((ele, index) =>
                                            <SwiperSlide key={index}>
                                                {
                                                    intro ?
                                                        intro[index] === true ?
                                                            <GameTimeQue ar={1} slidechange={slideIndex} que={answers[index - 1] === '0' ? 0 : 1} q={ele} setLevel={setLevel} level={level} index={index} onChange={(time) => handleTimeQuestionChange(index, time, ele.id)} />
                                                            :
                                                            ele.intro ?
                                                                <Continue que={answers[index - 1] === '0' ? 0 : 1} buttonText={t('Quiz.SubmitBtnText')} intro={toAR ? ele.intro.ar : ele.intro.en} onContine={() => handleIntro(index)} />
                                                                :
                                                                <>
                                                                    {
                                                                        intro[index] = true
                                                                    }
                                                                    <GameTimeQue ar={1} slidechange={slideIndex} que={answers[index - 1] === '0' ? 0 : 1} q={ele} setLevel={setLevel} level={level} index={index} onChange={(time) => handleTimeQuestionChange(index, time, ele.id)} />
                                                                </>
                                                        :
                                                        ''
                                                    // <GameTimeQue  q={ele} setLevel={setLevel} level={level} index={index} onChange={(time) => handleTimeQuestionChange(index, time, ele.id)} />

                                                }
                                            </SwiperSlide>
                                        )
                                    }
                                    {answers[1] == questions[1].options[0].id && (
                                        <>
                                            {
                                                questions[1].dependency.Yes.map(((ele, index) =>
                                                    <SwiperSlide key={index}>
                                                        {
                                                            intro ?
                                                                intro[index + 2] === true ?
                                                                    <GameTimeQue ar={1} slidechange={slideIndex} que={answers[index + 1] === '0' ? 0 : 1} setLevel={setLevel} level={level} q={ele} index={index + 2} onChange={(time) => handleTimeQuestionChange(index + 2, time, ele.id)} />

                                                                    :
                                                                    ele.intro ?
                                                                        <Continue que={answers[index + 1] === '0' ? 0 : 1} buttonText={t('Quiz.Intro.ContinueBtn')} intro={toAR ? ele.intro.ar : ele.intro.en} onContine={() => handleIntro(index + 2)} />
                                                                        :
                                                                        <>
                                                                            {
                                                                                intro[index + 2] = true
                                                                            }
                                                                            <GameTimeQue ar={1} slidechange={slideIndex} que={answers[index + 1] === '0' ? 0 : 1} setLevel={setLevel} level={level} q={ele} index={index + 2} onChange={(time) => handleTimeQuestionChange(index + 2, time, ele.id)} />
                                                                        </>
                                                                :
                                                                ''
                                                            // <GameTimeQue  setLevel={setLevel} level={level} q={ele} index={index + 2} onChange={(time) => handleTimeQuestionChange(index + 2, time, ele.id)} />
                                                        }
                                                    </SwiperSlide>
                                                ))
                                            }
                                        </>
                                    )}
                                    {answers[1] == questions[1].options[1].id && (
                                        <>
                                            {
                                                questions[1].dependency.No.map(((ele, index) =>
                                                    <SwiperSlide key={index}>
                                                        {
                                                            intro ?
                                                                intro[index + 2] === true ?
                                                                    <GameTimeQue ar={1} slidechange={slideIndex} que={answers[index + 1] === '0' ? 0 : 1} setLevel={setLevel} level={level} q={ele} index={index + 5} onChange={(time) => handleTimeQuestionChange(index + 2, time, ele.id)} />

                                                                    :
                                                                    ele.intro ?
                                                                        <Continue que={answers[index + 1] === '0' ? 0 : 1} buttonText={t('Quiz.Intro.ContinueBtn')} intro={toAR ? ele.intro.ar : ele.intro.en} onContine={() => handleIntro(index + 2)} />
                                                                        :
                                                                        <>
                                                                            {
                                                                                intro[index + 2] = true
                                                                            }
                                                                            <GameTimeQue ar={1} slidechange={slideIndex} que={answers[index + 1] === '0' ? 0 : 1} setLevel={setLevel} level={level} q={ele} index={index + 5} onChange={(time) => handleTimeQuestionChange(index + 2, time, ele.id)} />
                                                                        </>
                                                                :
                                                                ''
                                                            // <GameTimeQue  setLevel={setLevel} level={level} q={ele} index={index + 5} onChange={(time) => handleTimeQuestionChange(index + 2, time, ele.id)} />
                                                        }

                                                    </SwiperSlide>
                                                ))
                                            }
                                        </>
                                    )}
                                    {
                                        (answers[1] != questions[1].options[0].id && answers[1] != questions[1].options[1].id) && (
                                            <>
                                                <SwiperSlide>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                </SwiperSlide>
                                            </>
                                        )
                                    }
                                </Swiper>
                                <div dir={direction}>
                                    <CustomPagination
                                        activeIndex={swiperRTLRef.current?.swiper?.activeIndex}
                                        totalSlides={swiperRTLRef.current?.swiper?.slides.length}
                                        onButtonClick={handleButtonClick}
                                        answers={answers}
                                        intro={intro}
                                    />
                                </div>
                            </div>
                            <div className={`SwiperParent ${toAR ? '' : 'swiperShow'}`}>
                                <Swiper
                                    ref={swiperRef}
                                    onSlideChange={handleSlideChange}
                                    className="mySwiper">
                                    {
                                        questions.map((ele, index) =>
                                            <SwiperSlide key={index}>
                                                {
                                                    intro ?
                                                        intro[index] === true ?
                                                            <GameTimeQue ar={0} slidechange={slideIndex} que={answers[index - 1] === '0' ? 0 : 1} q={ele} setLevel={setLevel} level={level} index={index} onChange={(time) => handleTimeQuestionChange(index, time, ele.id)} />
                                                            :
                                                            ele.intro ?
                                                                <Continue que={answers[index - 1] === '0' ? 0 : 1} buttonText={t('Quiz.SubmitBtnText')} intro={toAR ? ele.intro.ar : ele.intro.en} onContine={() => handleIntro(index)} />
                                                                :
                                                                <>
                                                                    {
                                                                        intro[index] = true
                                                                    }
                                                                    <GameTimeQue ar={0} slidechange={slideIndex} que={answers[index - 1] === '0' ? 0 : 1} q={ele} setLevel={setLevel} level={level} index={index} onChange={(time) => handleTimeQuestionChange(index, time, ele.id)} />
                                                                </>

                                                        :
                                                        ''
                                                    // <GameTimeQue  q={ele} setLevel={setLevel} level={level} index={index} onChange={(time) => handleTimeQuestionChange(index, time, ele.id)} />

                                                }
                                            </SwiperSlide>
                                        )
                                    }
                                    {answers[1] == questions[1].options[0].id && (
                                        <>
                                            {
                                                questions[1].dependency.Yes.map(((ele, index) =>
                                                    <SwiperSlide key={index}>
                                                        {
                                                            intro ?
                                                                intro[index + 2] === true ?
                                                                    <GameTimeQue ar={0} slidechange={slideIndex} que={answers[index + 1] === '0' ? 0 : 1} setLevel={setLevel} level={level} q={ele} index={index + 2} onChange={(time) => handleTimeQuestionChange(index + 2, time, ele.id)} />

                                                                    :
                                                                    ele.intro ?
                                                                        <Continue que={answers[index + 1] === '0' ? 0 : 1} buttonText={t('Quiz.Intro.ContinueBtn')} intro={toAR ? ele.intro.ar : ele.intro.en} onContine={() => handleIntro(index + 2)} />
                                                                        :
                                                                        <>
                                                                            {
                                                                                intro[index + 2] = true
                                                                            }
                                                                            <GameTimeQue ar={0} slidechange={slideIndex} que={answers[index + 1] === '0' ? 0 : 1} setLevel={setLevel} level={level} q={ele} index={index + 2} onChange={(time) => handleTimeQuestionChange(index + 2, time, ele.id)} />
                                                                        </>
                                                                :
                                                                ''
                                                            // <GameTimeQue  setLevel={setLevel} level={level} q={ele} index={index + 2} onChange={(time) => handleTimeQuestionChange(index + 2, time, ele.id)} />
                                                        }
                                                    </SwiperSlide>
                                                ))
                                            }
                                        </>
                                    )}
                                    {answers[1] == questions[1].options[1].id && (
                                        <>
                                            {
                                                questions[1].dependency.No.map(((ele, index) =>
                                                    <SwiperSlide key={index}>
                                                        {
                                                            intro ?
                                                                intro[index + 2] === true ?
                                                                    <GameTimeQue ar={0} slidechange={slideIndex} que={answers[index + 1] === '0' ? 0 : 1} setLevel={setLevel} level={level} q={ele} index={index + 5} onChange={(time) => handleTimeQuestionChange(index + 2, time, ele.id)} />

                                                                    :
                                                                    ele.intro ?
                                                                        <Continue que={answers[index + 1] === '0' ? 0 : 1} buttonText={t('Quiz.Intro.ContinueBtn')} intro={toAR ? ele.intro.ar : ele.intro.en} onContine={() => handleIntro(index + 2)} />
                                                                        :
                                                                        <>
                                                                            {
                                                                                intro[index + 2] = true
                                                                            }
                                                                            <GameTimeQue ar={0} slidechange={slideIndex} que={answers[index + 1] === '0' ? 0 : 1} setLevel={setLevel} level={level} q={ele} index={index + 5} onChange={(time) => handleTimeQuestionChange(index + 2, time, ele.id)} />
                                                                        </>
                                                                :
                                                                ''
                                                            // <GameTimeQue  setLevel={setLevel} level={level} q={ele} index={index + 5} onChange={(time) => handleTimeQuestionChange(index + 2, time, ele.id)} />
                                                        }

                                                    </SwiperSlide>
                                                ))
                                            }
                                        </>
                                    )}
                                    {
                                        (answers[1] != questions[1].options[0].id && answers[1] != questions[1].options[1].id) && (
                                            <>
                                                <SwiperSlide>

                                                </SwiperSlide>
                                                <SwiperSlide>
                                                </SwiperSlide>
                                            </>
                                        )
                                    }
                                </Swiper>
                                <div dir={direction}>
                                    <CustomPagination
                                        activeIndex={swiperRef.current?.swiper?.activeIndex}
                                        totalSlides={swiperRef.current?.swiper?.slides.length}
                                        onButtonClick={handleButtonClick}
                                        answers={answers}
                                        intro={intro}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
function CustomPagination({ activeIndex, totalSlides, onButtonClick, answers, intro }) {
    console.log(activeIndex, totalSlides, onButtonClick, answers, intro);
    const buttons = [];

    // Check if all required props are available
    for (let i = 0; i < (totalSlides ? totalSlides : 4); i++) {
        buttons.push(
            <span
                key={i}
                className={`${(activeIndex === i && intro[i] === true) ? 'active' : ''} ${(answers[i] === null || answers[i] === undefined) ? '' : 'answered'}`}
                onClick={() => onButtonClick(i)}
            >
            </span>
        );
    }

    return <div className="custom-pagination">{buttons}</div>;
}
