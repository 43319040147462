import { Outlet } from 'react-router-dom';
import './App.css';
import {  useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { checkLang } from './Partials/i18n';
import Footer from './Partials/Footer';
import Constant from './Partials/Constant';
import NavBar from './Partials/Navbar/NavBar';
function App() {
  const [dir, setDir] = useState('ltr');
  const { i18n} = useTranslation();

  const { lang } = useParams()
  useEffect(() => {
    if(lang==='ar') 
    {
      setDir('rtl')
    }
    else{
      setDir('ltr')
    }
    checkLang(i18n,lang)
  }, [lang])
  
  
  return (
    <>
    <div className={`mainDiv ${dir==='ltr'?"ltr":"rtl"}`}>
    <NavBar/>
      <Outlet />
      <Footer/>
    </div>
    </>
  );
}

export default App;