import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import i18n from './Services/I18n';
import Local from './Pages/Local';
import Result from './Pages/Result/Result';
import Quiz from './Pages/Quiz';
import Home from './Pages/Home/Home';
import Share from './Pages/Share/Share';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<I18nextProvider i18n={i18n}>
  <BrowserRouter>
  <Routes>
        <Route path="/" element={ <Local/> } /> 
        <Route path="/:lang/*" element={<App/>}>
          <Route path="" element={ <Home/> }/>
          <Route path="share" element={ <Share/> }/>
          <Route path="quiz" element={<Quiz/> }/>
          <Route path="result" element={ <Result/> } />
        </Route>
    </Routes>
  </BrowserRouter>
</I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
