import React from 'react'
import './Home.css'
import '../../assets/CSS/Button.css'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react';
import { useState } from 'react';
import WebGif from '../../assets/GIf/HUDforanimationVertical.gif'
import MobGif from '../../assets/GIf/HUDforanimationHorizontal.gif'
import UpdatedWebGif from '../../assets/GIf/updatedAnimationWeb.gif'
import UpdatedMobGif from '../../assets/GIf/updatedAnimationMobile.gif'
export default function Home() {
    const [dir, setDir] = useState('ltr');
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { lang } = useParams()
    useEffect(() => {
        if (lang === 'ar') {
            setDir('rtl')
        }
        else {
            setDir('ltr')
        }
    }, [lang])
    function removePrevQuiz() {
        localStorage.removeItem('answers')
        let intro =new Array(1)
        intro[0]=true;
        localStorage.setItem('intro',JSON.stringify(intro))
    }
    return (
        <>
            <div className="HomeMain homeMainSh">
                <div className="leftHome">
                    <Link to={`/${lang}`}>
                        <img src={UpdatedWebGif} alt="" />
                    </Link>
                    <Link to={`/${lang}`}>
                        <img src={UpdatedMobGif} alt="" />
                    </Link>
                </div>
                <div className="rightHome" dir={dir}>
                    <div className="rightHomeMain">
                        <div className="HomeHeading">
                            <h2>{t("Home.heading.head")}</h2>
                            <div dangerouslySetInnerHTML={{ __html: t("Home.heading.Para") }} />
                        </div>
                        <div className="CtaButtons">
                            <div className="QuizBtn btn_Parent btn_red" onClick={() => {
                                removePrevQuiz()
                                navigate(`/${lang}/quiz`)
                            }}>
                                <button className={``} >{t("Home.ButtonText.getStarted")}</button>
                            </div>
                            <div className="QuizBtn btn_Parent btn_transparent" onClick={()=>{
                                    localStorage.removeItem('answers')
                                    localStorage.removeItem('intro')
                                    navigate(`/${lang}/quiz`)

                                }}>
                                <button className={``}  >{t("Home.ButtonText.IntroBtn")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
