import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { BsDiscord, BsYoutube, BsInstagram, BsTwitter } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'
import Hamburger from "../../assets/Images/BurgerMenu.png"
import CrossImg from "../../assets/Images/X.png"
import ValLogo from "../../assets/Images/valorantLogo.png"
import RiotLogo from "../../assets/Images/Riot_Games-Logo.wine.png"
import Globe from "../../assets/Images/glob.png"
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs"
import './Nav.css'
import Constant from '../Constant';
export default function NavBar() {
    const { pathname, search, hash } = useLocation();
    const { lang } = useParams()
    const [isDir, setDir] = useState('ltr')
    const { i18n, t } = useTranslation();
    const navigate = useNavigate()
    const [isSidebar, setIsSidebar] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }
    const ref = useRef(null);
    useEffect(() => {
        // add event listener to document object
        document.addEventListener('click', handleClickOutside);

        // cleanup function to remove event listener
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        // check whether the clicked element is outside the dropdown menu
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (lang === 'ar') {
            setDir('rtl')
        }
        else {
            setDir('ltr')
        }
    }, [lang])
    // const handleChangeLanguage = (langValue) => {
    //     let result;
    //     console.log(window.location);
    //     // const regex = /^\/(fr|en)(\/[a-zA-Z0-9]+)*$/;
    //     if (langValue === 'ar' && lang === 'en') {
    //         result = window.location.pathname.replace('en', "ar");
    //         i18n.changeLanguage(langValue)
    //     }
    //     if (langValue === 'en' && lang === 'ar') {
    //         result = window.location.pathname.replace('ar', "en");
    //         i18n.changeLanguage(langValue)
    //     }
    //     navigate(result)
    // };

    const handleChangeLanguage = (langValue) => {
        const pathnameParts = pathname.split('/');
        const langIndex = pathnameParts.indexOf(lang);
        if (langIndex !== -1) {
            pathnameParts.splice(langIndex, 1, langValue);
        } else {
            pathnameParts.splice(1, 0, langValue);
        }
        const newUrl = `${pathnameParts.join('/')}${search}${hash}`;
        i18n.changeLanguage(langValue);
        navigate(newUrl);
    };

    function handleSidebar() {
        setIsSidebar(!isSidebar)
    }
    return (
        <>
            <nav className='nav_riot'>
                <div className="navInner">
                    <div className="hamburgerMenu">
                        <div className={`HamburgerBtn sidebarBtn ${isSidebar ? 'show' : ''}`}>
                            <button onClick={handleSidebar}>
                                <img src={Hamburger} alt="" />
                            </button>
                        </div>
                    </div>
                    <div className={`SidebarInner ${isSidebar ? 'show' : ''}`}>
                        <div className="SideHeader sidebarBtn">
                            <button onClick={handleSidebar}>
                                <img src={CrossImg} alt="" />
                            </button>
                        </div>
                        <div className="sideMenu">
                            <div className="nav_Menu">
                                <div className="nav_brand">
                                    <div className="val_logo">
                                        <Link to={Constant.Valorant} target='_blank' onClick={() => {
                                            handleSidebar()
                                        }}>
                                            <img src={ValLogo} alt="" />
                                        </Link>
                                    </div>
                                    <div className="riot_logo">
                                        {/* <Link to={Constant.Riot} target='_blank'  onClick={()=>{
                                            handleSidebar()
                                        }}> */}
                                        <img src={RiotLogo} alt="" />
                                        {/* </Link> */}
                                    </div>
                                </div>
                                <div className="nav_Links">
                                    <ul className="sideLinks">
                                        <li>
                                            <Link to={Constant.TermsCondition} target='_blank' onClick={() => {
                                                handleSidebar()
                                            }}>
                                                {t("Footer.Terms")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={Constant.Privacy} target='_blank' onClick={() => {
                                                handleSidebar()
                                            }}>
                                                {t("Footer.Privacy")}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="navsocial">
                                    <ul className="socailLinks">
                                        <li>
                                            <Link to={Constant.Discord} target='_blank' onClick={() => {
                                                handleSidebar()
                                            }}>
                                                <BsDiscord />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={Constant.Youtube} target='_blank' onClick={() => {
                                                handleSidebar()
                                            }}>
                                                <BsYoutube />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={Constant.Instagram} target='_blank' onClick={() => {
                                                handleSidebar()
                                            }}>
                                                <BsInstagram />
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul className="socailLinks">
                                        <li>
                                            <Link to={Constant.Twitter} target='_blank' onClick={() => {
                                                handleSidebar()
                                            }}>
                                                <BsTwitter />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={Constant.Facebook} target='_blank' onClick={() => {
                                                handleSidebar()
                                            }}>
                                                <FaFacebookF />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`sidebarOverlay  ${isSidebar ? 'show' : ''}`} onClick={handleSidebar}></div>
                    <div className="LanguageSwitch" dir={isDir}>
                        <div className="nav_switch">
                            <div className="Switch">
                                <div className='langSwitch' ref={ref}>
                                    <div className="globeImg">
                                        <img src={Globe} alt="" />
                                    </div>
                                    <button onClick={toggleDropdown}>
                                        {
                                            lang === 'ar' ?
                                                t('Quiz.Switch.ar')
                                                :
                                                t('Quiz.Switch.en')
                                        }
                                        <span onClick={toggleDropdown} className={`${isOpen ? 'iconup' : "icondown"}`}>
                                            <BsFillCaretUpFill />
                                            <BsFillCaretDownFill />
                                        </span>
                                    </button>
                                    {isOpen && (
                                        <div className='drop_opt'>
                                            {
                                                lang === 'ar' ?
                                                    <span onClick={() => {
                                                        handleChangeLanguage('en')
                                                        toggleDropdown()
                                                    }}>{t('Quiz.Switch.en')}</span>
                                                    :
                                                    <span onClick={() => {
                                                        handleChangeLanguage('ar')
                                                        toggleDropdown()
                                                    }}
                                                    >{t('Quiz.Switch.ar')}</span>
                                            }
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}
